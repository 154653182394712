#projects {
  @media screen and (min-width: 768px) {
    padding-right: 45px;
    padding-left: 45px;
  }
  @media screen and (min-width: 1150px) {
    padding-right: 60px;
    padding-left: 60px;
  }
  @media screen and (min-width: 1366px) {
    padding-right: 90px;
    padding-left: 90px;
  }
  @media screen and (min-width: 1600px) {
    padding-right: calc(120px + ((100% - 1600px)/2));
    padding-left: calc(120px + ((100% - 1600px)/2));
  }
  .headingWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      margin-bottom: 5px;
      @media screen and (min-width: 900px) {
        margin-bottom: 10px;
      }
      @media screen and (min-width: 1366px) {
        margin-bottom: 15px;
      }
    }
    .subHeading {
      font-size: 1rem;
      text-transform: lowercase;
      margin-bottom: 20px;
      color: black;
      @media screen and (min-width: 768px) {
        font-size: 1.1rem;
      }
      @media screen and (min-width: 900px) {
        font-size: 1.2rem;
        margin-bottom: 30px;
      }
      @media screen and (min-width: 1150px) {
        font-size: 1.3rem;
      }
      @media screen and (min-width: 1366px) {
        font-size: 1.4rem;
        margin-bottom: 45px;
      }
    }
  }
  .items {
    display: none;
    @media screen and (min-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      .item {
        margin-bottom: 30px;
        .border {
          .content {
            display: flex;
            justify-content: space-between;
            .imageContainer {
              width: 115px;
              img {
                width: 100%;
              }
            }
            .textContainer {
              width: calc(100% - 135px);
            }
          }
        }
      }
    }
    @media screen and (min-width: 900px) {
      .item {
        .border {
          .content {
            .imageContainer {
              width: 130px;
            }
            .textContainer {
              width: calc(100% - 160px);
            }
          }
        }
      }
    }
    @media screen and (min-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: stretch;
      margin-bottom: -30px; // Compensating for bottom margin of item
      .item {
        width: calc(33.33% - 20px);
        .border {
          height: 100%;
          padding: 30px 30px;
          box-shadow: 0px 2px 5px $grayLight;
          h3 {
            text-align: center;
            margin-bottom: 20px;
          }
          .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            .imageContainer {
              width: 130px;
              margin-bottom: 20px;
              img {
                width: 100%;
              }
            }
            .textContainer {
              width: 100%;
              p {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: 1150px) {
      margin-bottom: -60px; // Compensating for bottom margin of item
      .item {
        width: calc(33.33% - 40px);
        margin-bottom: 60px;
        .border {
          h3 {
            margin-bottom: 30px;
          }
          .content {
            .imageContainer {
              margin-bottom: 30px;
            }
            .textContainer {
              p {
                font-size: 1.05rem;
              }
            }
          }
        }
      }
    }
    @media screen and (min-width: 1366px) {
      .item {
        .border {
          .content {
            .imageContainer {
              width: 140px;
            }
            .textContainer {
              p {
                font-size: 1.1rem;
              }
            }
          }
        }
      }
    }
  }
}
