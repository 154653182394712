/* Import fonts */
@font-face {
  font-family: 'Rabbid Highway Sign II';
  src: url('../assets/fonts/RabbidHighwaySignII.otf') format('opentype');
}

@mixin OpenSansRegular {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
}

@mixin OpenSansSemiBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
}

@mixin OpenSansBold {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
}

@mixin OpenSansItalic {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-style: italic;
}

/* Import global stylesheets */
@import './colors.scss';
@import './variables.scss';
@import './breakpoints.scss';
@import './resets.scss';
@import './master.scss';

/* Import view stylesheets */
@import '../views/splash/Splash.scss';
@import '../views/about/About.scss';
@import '../views/services/Services.scss';
@import '../views/testimonials/Testimonials.scss';
@import '../views/projects/Projects.scss';
@import '../views/products/Products.scss';
@import '../views/community/Community.scss';
@import '../views/partners/Partners.scss';
@import '../views/contact/Contact.scss';
