#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  background-image: linear-gradient(to top, $brownPale, white 20%);
  h2 {
    margin-bottom: 20px;
    @media screen and (min-width: 1366px) {
      margin-bottom: 45px;
    }
  }
  .textLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    a {
      color: black;
      margin-bottom: 30px;
    }
    @media screen and (min-width: 900px) {
      margin-bottom: 15px;
      a {
        font-size: 1.1rem;
      }
    }
    @media screen and (min-width: 1024px) {
      a {
        font-size: 1.2rem;
      }
    }
    @media screen and (min-width: 1150px) {
      a {
        font-size: 1.3rem;
      }
    }
    @media screen and (min-width: 1366px) {
      margin-bottom: 30px;
      a {
        font-size: 1.4rem;
      }
    }
  }
  .iconLinks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 150px;
    margin-bottom: 60px;
    a {
      height: 60px;
      width: 60px;
      font-size: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;
      margin-bottom: 30px;
      &:hover {
        text-decoration: none;
      }
    }
    @media screen and (min-width: 568px) {
      width: 340px;
    }
    @media screen and (min-width: 768px) {
      margin-bottom: 75px;
    }
    @media screen and (min-width: 900px) {
      width: 460px;
      margin-bottom: 90px;
      a {
        height: 75px;
        width: 75px;
        font-size: 75px;
        margin-bottom: 60px;
      }
    }
    @media screen and (min-width: 1366px) {
      width: 600px;
      a {
        height: 75px;
        width: 75px;
        font-size: 75px;
        margin-bottom: 90px;
      }
    }
  }
  .creativeLinks {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: .9rem;
    }
    @media screen and (min-width: 568px) {
      width: 508px;
      flex-direction: row;
      justify-content: space-between;
    }
    @media screen and (min-width: 768px) {
      padding-bottom: 15px;
    }
    @media screen and (min-width: 900px) {
      width: 740px;
      padding-bottom: 30px;
      span {
        font-size: .95rem;
      }
    }
    @media screen and (min-width: 1024px) {
      span {
        font-size: 1rem;
      }
    }
    @media screen and (min-width: 1150px) {
      span {
        font-size: 1.05rem;
      }
    }
    @media screen and (min-width: 1366px) {
      width: 1126px;
    }
    @media screen and (min-width: 1600px) {
      span {
        font-size: 1.1rem;
      }
    }
  }
}