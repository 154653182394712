#products {
  .headingWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    h2 {
      margin-bottom: 5px;
      @media screen and (min-width: 900px) {
        margin-bottom: 10px;
      }
      @media screen and (min-width: 1366px) {
        margin-bottom: 15px;
      }
    }
    .subHeading {
      @include OpenSansSemiBold;
      font-size: .9rem;
      color: black;
      margin-bottom: 3px;
      @media screen and (min-width: 768px) {
        font-size: .95rem;
      }
      @media screen and (min-width: 900px) {
        font-size: 1rem;
        margin-bottom: 5px;
      }
      @media screen and (min-width: 1150px) {
        font-size: 1.1rem;
        margin-bottom: 8px;
      }
      @media screen and (min-width: 1366px) {
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
    }
    .productsDivider {
      line-height: 0;
      margin-top: -5px;
      span {
        display: inline-block;
        color: $brownPrimary;
        transform: scale(41, 0.3) translateX(1.75px); // Hackery to be sure.
      }
      margin-bottom: 30px;
      @media screen and (min-width: 768px) {
        margin-bottom: 45px;
        span {
          transform: scale(43, 0.3) translateX(1.75px);
        }
      }
      @media screen and (min-width: 900px) {
        span {
          transform: scale(45, 0.4) translateX(1.75px);
        }
        @media screen and (min-width: 1150px) {
          span {
            transform: scale(50, 0.5) translateX(1.75px);
          }
        }
        @media screen and (min-width: 1366px) {
          margin-bottom: 60px;
          span {
            transform: scale(55, 0.5) translateX(1.75px);
          }
        }
      }
    }
  }
  .item {
    .content {
      .imageContainer {
        display: none;
        @media screen and (min-width: 768px) {
          display: initial;
          float: left;
          margin-right: 20px;
          width: 130px;
          img {
            width: 100%;
          }
        }
        @media screen and (min-width: 900px) {
          margin-right: 30px;
          width: 160px;
          img {
            width: 100%;
          }
        }
        @media screen and (min-width: 1024px) {
          width: 180px;
        }
        @media screen and (min-width: 1366px) {
          width: 200px;
        }
      }
      .textContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          width: 100%;
        }
        .appButtonWrapper {
          width: 100%;
          max-width: 290px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 15px;
          .appButton {
            height: 40px;
            img {
              width: auto;
              height: 100%;
            }
          }
          @media screen and (min-width: 900px) {
            max-width: 320px;
            .appButton {
              height: 45px;
            }
          }
          @media screen and (min-width: 1150px) {
            max-width: 340px;
            padding-top: 30px;
          }
          @media screen and (min-width: 1366px) {
            max-width: 380px;
            padding-top: 45px;
            .appButton {
              height: 50px;
            }
          }
        }
      }
    }
  }
}