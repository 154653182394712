/* SlothWerks Brown */
$brownPrimary: #d46b29;
$brownLight: #da976c;
$brownDark: #a15120;
$brownDarker: #542a10;
$brownPale: #fbe5d8; // was #f7ebe8
$brownPaler: #fdfaf9;

/* Tech Purple */
$purplePrimary: #6702c8;
$purpleLight: #8b42d1;
$purpleDark: #4d0195;
$purpleDarker: #311747;
$purplePale: #ebe7f5;
$purplePaler: #faf9fd;

/* Blue */
$bluePrimary: #2a76d4;
$blueLight: #6c9edb;
$blueLighter: #bdcaeb;
$blueDark: #1f5aa0;
$blueDarker: #0f2f55;
$bluePale: #e8ecf7;
$bluePaler: #f9fafd;

/* Maize */
$maizePrimary: #d4c029;
$maizeLight: #dbcf6d;
$maizeDark: #a1921f;
$maizeDarker: #534d0f;

/* Red */
$redPrimary: #d44029;

/* Green */
$greenPrimary: #29d487;
$greenLight: #81dda8;
$greenLighter: #bdebcf;
$greenDark: #1b9b62;
$greenDarker: #0a5936;
$greenPale: #e8f7ed;
$greenPaler: #f9fdfb;

/* Yellow */
$yellowPrimary: #fae22e;

/* Gray */
$grayPrimary: #7c7c7c; // Black on white matte @ 80% opacity
$grayLight: #bcbcbc; // Black on white matte @ 50% opacity

/*
  To create shades, follow this rough guide:
  Light ... Primary on white matte @ roughly 80% opacity
  Lighter ... Primary on white matte @ roughly 50% opacity
  Dark ... Primary on black matte @ roughly 50% opacity
  Darker ... Primary on black matte @ roughly 15% opacity
  Pale ... Primary on white matte @ roughly 20% opacity
  Paler ... Primary on white matte @ roughly 5% opacity
*/