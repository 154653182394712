#testimonials {
  overflow: hidden;
  .items {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative; // for absolutely positioned children
    height: 240px; // Fixed height for all quotes
    @media screen and (min-width: 768px) {
      height: 160px; // Fixed height for all quotes
    }
    @media screen and (min-width: 900px) {
      height: 200px; // Fixed height for all quotes
    }
    @media screen and (min-width: 1150px) {
      height: 230px; // Fixed height for all quotes
    }
    @media screen and (min-width: 1366px) {
      height: 260px; // Fixed height for all quotes
    }
    .item {
      position: absolute;
      width: 250px;
      display: flex;
      flex-direction: column;
      opacity: 0; // For animation
      margin-left: -30px; // For animation
      transition: opacity 1s ease, margin-left 1s ease; // For animation
      .quoteContainer {
        position: relative; // for absolutely positioned children
        margin-bottom: 10px;
        .leftQuote {
          position: absolute;
          left: 0;
          top: 0;
          color: $brownPale;
          font-size: 2rem;
        }
        .rightQuote {
          position: absolute;
          right: 0;
          bottom: 0;
          color: $brownPale;
          font-size: 2rem;
        }
        .quoteText {
          position: relative;
          z-index: 1;
          font-size: 1rem;
          color: $brownDark;
          margin-bottom: 0;
          padding: 15px 0;
        }
      }
      .clientText {
        margin-bottom: 0;
        color: black;
      }
      @media screen and (min-width: 768px) {
        width: 500px;
        .clientText {
          margin-right: 45px;
        }
      }
      @media screen and (min-width: 900px) {
        .quoteContainer {
          .quoteText {
            font-size: 1.1rem;
            padding: 20px 10px;
          }
        }
        .clientText {
          font-size: 1.1rem;
        }
      }
      @media screen and (min-width: 1024px) {
        .quoteContainer {
          .quoteText {
            font-size: 1.2rem;
          }
        }
        .clientText {
          font-size: 1.2rem;
        }
      }
      @media screen and (min-width: 1150px) {
        width: 600px;
        .quoteContainer {
          margin-bottom: 20px;
          .quoteText {
            font-size: 1.3rem;
            line-height: 2rem;
          }
        }
        .clientText {
          font-size: 1.3rem;
        }
      }
      @media screen and (min-width: 1366px) {
        .quoteContainer {
          .leftQuote, .rightQuote {
            font-size: 2.5rem;
          }
          .quoteText {
            font-size: 1.4rem;
            line-height: 2.5rem;
          }
        }
        .clientText {
          font-size: 1.4rem;
        }
      }
    }
    // For animation
    .displayed {
      opacity: 1;
      margin-left: 0;
    }
  }
}