#community {
  position: relative; // For absolutely positioned background
  .background {
    position: absolute;
    width: 100%;
    @media screen and (min-width: 768px) {
      display: none;
    }
    .top {
      width: 100%;
      height: 50px;
      background-color: white;
      background-image: radial-gradient(circle at 50% calc(50px + 855px), $brownPale, $brownPale calc(900px), white calc(900px));
      @media screen and (min-width: 568px) {
        background-image: radial-gradient(circle at 50% calc(50px + 1755px), $brownPale, $brownPale calc(1800px), white calc(1800px));
      }
    }
    .bottom {
      width: 100%;
      height: 480px;
      background-color: $brownPale;
      background-image: radial-gradient(circle at 50% calc(500px + 830px), white, white calc(900px), $brownPale calc(900px));
      @media screen and (min-width: 568px) {
        background-image: radial-gradient(circle at 50% calc(500px + 1730px), white, white calc(1800px), $brownPale calc(1800px));
      }
    }
  }
  .contentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    padding-top: 45px;
    height: 470px; // Necessary due to static height background
    h2 {
      width: 290px;
    }
    .content {
      padding-bottom: 30px;
      .imageContainer {
        display: none;
      }
      .textContainer {
        width: 290px;
      }
      .readMoreWrapper {
        width: 100%;
        padding-top: 15px;
        display: flex;
        justify-content: center;
        .readMore {
          position: relative; // For absolutely-positioned icon
          display: flex;
          flex-direction: column;
          align-items: center;
          color: $purplePrimary;
          margin-bottom: 0;
          .text {
            @include OpenSansSemiBold;
          }
          .icon {
            position: absolute; // Take out of normal flow
            margin-top: 20px;
            font-size: 1.5rem;
            animation-duration: .75s;
            animation-name: readMoreBounce;
            animation-iteration-count: infinite;
            animation-direction: alternate;
          }
        }
      }
    }
    @media screen and (min-width: 768px) {
      height: auto;
      background-image: radial-gradient(circle at center, $brownPaler, $brownPale);
      h2 {
        width: 100%;
      }
      .content {
        padding-bottom: 45px;
        .imageContainer {
          display: initial;
          width: 50%;
          float: right;
          margin-left: 20px;
          margin-bottom: 20px;
          img {
            width: 100%;
          }
        }
        .textContainer {
          width: auto;
        }
      }
    }
    @media screen and (min-width: 900px) {
      padding-top: 60px;
      .content {
        padding-bottom: 50px;
        .imageContainer {
          margin-left: 30px;
          margin-bottom: 30px;
          img {
            width: 100%;
          }
        }
        .textContainer {
          width: auto;
        }
        .readMoreWrapper {
          .readMore {
            .text {
              font-size: 1.1rem;
            }
            .icon {
              margin-top: 25px;
              font-size: 1.75rem;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1024px) {
      .content {
        .readMoreWrapper {
          .readMore {
            .text {
              font-size: 1.15rem;
            }
            .icon {
              font-size: 2rem;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1150px) {
      padding-top: 90px;
      .content {
        padding-bottom: 80px;
        .imageContainer {
          width: 45%;
          margin-left: 45px;
          margin-bottom: 45px;
        }
        .readMoreWrapper {
          .readMore {
            .text {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1366px) {
      .content {
        .readMoreWrapper {
          .readMore {
            .text {
              font-size: 1.25rem;
            }
            .icon {
              margin-top: 30px;
            }
          }
        }
      }
    }
    @media screen and (min-width: 1600px) {
      padding-top: 120px;
      .content {
        padding-bottom: 110px;
      }
    }
  }
}