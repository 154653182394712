#splash {
  position: relative; // For absolutely positioned background
  .background1 {
    position: absolute;
    width: 100%;
    height: 510px;
    margin-top: -75px;
    background-color: $brownPale;
  }
  .background2 {
    position: absolute;
    width: 100%;
    height: 510px;
    margin-top: -75px;
    background-image: radial-gradient(circle at 50% 515px, white, white 200px, rgba(255, 255, 255, .001) 200px);
  }  
  @media screen and (min-width: 400px) {
    .background1, .background2 {
      height: 520px;
    }
    .background2 {
      background-image: radial-gradient(circle at 50% 515px, white, white 210px, rgba(255, 255, 255, .001) 210px);
    }
  }
  @media screen and (min-width: 568px) {
    .background1, .background2 {
      height: 310px;
    }
    .background2 {
      background-image: radial-gradient(circle at calc(100% - 133px) 315px, white, white 160px, rgba(255, 255, 255, .001) 160px);
    }
  }
  @media screen and (min-width: 667px) {
    .background1, .background2 {
      height: 355px;
    }
    .background2 {
      background-image: radial-gradient(circle at calc(100% - 160px) 355px, white, white 195px, rgba(255, 255, 255, .001) 195px);
    }
  }
  @media screen and (min-width: 768px) {
    .background1, .background2 {
      height: 370px;
    }
    .background1 {
      background-color: transparent;
      background-image: linear-gradient(to bottom, white, $brownPale);
    }
    .background2 {
      background-image: radial-gradient(circle at calc(100% - 180px) 370px, white, white 210px, rgba(255, 255, 255, .001) 210px);
    }
  }
  @media screen and (min-width: 900px) {
    .background1, .background2 {
      height: 440px;
    }
    .background2 {
      background-image: radial-gradient(circle at calc(100% - 230px) 430px, white, white 255px, rgba(255, 255, 255, .001) 255px);
    }
  }
  @media screen and (min-width: 1024px) {
    .background1, .background2 {
      height: 500px;
    }
    .background2 {
      background-image: radial-gradient(circle at calc(745px + ((100% - 1024px)/2)) 500px, white, white 320px, rgba(255, 255, 255, .001) 320px);
    }
  }
  @media screen and (min-width: 1150px) {
    .background1, .background2 {
      height: 550px;
    }
    .background2 {
      background-image: radial-gradient(circle at calc(830px + ((100% - 1150px)/2)) 540px, white, white 370px, rgba(255, 255, 255, .001) 370px);
    }
  }
  @media screen and (min-width: 1366px) {
    .background1, .background2 {
      height: 700px;
    }
    .background2 {
      background-image: radial-gradient(circle at calc(970px + ((100% - 1366px)/2)) 680px, white, white 440px, rgba(255, 255, 255, .001) 440px);
    }
  }
  @media screen and (min-width: 1600px) {
    .background1, .background2 {
      height: 770px;
    }
    .background2 {
      background-image: radial-gradient(circle at calc(1024px + ((100% - 1600px)/2)) 780px, white, white 500px, rgba(255, 255, 255, .001) 500px);
    }
  }
  
  .contentWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    @media screen and (min-width: 568px) {
      padding-top: 30px;
      padding-left: 30px;
      padding-right: 30px;
      flex-direction: row;
      align-items: initial;
      justify-content: space-between;
    }
    @media screen and (min-width: 768px) {
      padding-left: 45px;
      padding-right: 45px;
    }
    @media screen and (min-width: 900px) {
      padding-top: 60px;
      padding-left: 60px;
      padding-right: 60px;
    }
    @media screen and (min-width: 1024px) {
      padding-left: calc(60px + ((100% - 1024px)/2));
      padding-right: calc(60px + ((100% - 1024px)/2));
    }
    @media screen and (min-width: 1150px) {
      padding-left: calc(60px + ((100% - 1150px)/2));
      padding-right: calc(60px + ((100% - 1150px)/2));
    }
    @media screen and (min-width: 1366px) {
      padding-top: 90px;
      padding-left: calc(90px + ((100% - 1366px)/2));
      padding-right: calc(90px + ((100% - 1366px)/2));
    }
    @media screen and (min-width: 1600px) {
      padding-top: 60px;
      padding-left: calc(90px + ((100% - 1600px)/2));
      padding-right: calc(90px + ((100% - 1600px)/2));
    }
    .textContainer {
      display: flex;
      flex-direction: column;
      padding-bottom: 60px;
      @media screen and (min-width: 568px) {
        padding-bottom: 0;
      }
      span {
        text-align: left;
        font-family: "Rabbid Highway Sign II";
        color: black;
        font-size: 2rem;
        line-height: 2.5rem;
        margin-bottom: 10px;
        opacity: 0;
        animation-name: splashTextFadeIn;
        animation-duration: 1s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in;
      }
      .code {
        animation-delay: .25s;
      }
      .communication {
        animation-delay: 1s;
      }
      .community {
        animation-delay: 1.75s;
      }
      @media screen and (min-width: 667px) {
        span {
          font-size: 2.25rem;
          line-height: 2.75rem;
          margin-bottom: 15px;
        }
      }
      @media screen and (min-width: 768px) {
        span {
          font-size: 2.75rem;
          line-height: 3rem;
          margin-bottom: 20px;
        }
      }
      @media screen and (min-width: 1024px) {
        span {
          font-size: 3rem;
          line-height: 3.5rem;
          margin-bottom: 20px;
        }
      }
      @media screen and (min-width: 1150px) {
        span {
          font-size: 3.25rem;
          line-height: 3.75rem;
          margin-bottom: 30px;
        }
      }
      @media screen and (min-width: 1366px) {
        span {
          font-size: 3.5rem;
          line-height: 4rem;
          margin-bottom: 45px;
        }
      }
      @media screen and (min-width: 1600px) {
        span {
          font-size: 4rem;
          line-height: 5rem;
          margin-bottom: 45px;
        }
      }
    }
    .imageContainer {
      width: 260px;
      @media screen and (min-width: 400px) {
        width: 280px;
      }
      @media screen and (min-width: 568px) {
        padding-top: 75px;
        width: 205px;
      }
      @media screen and (min-width: 667px) {
        padding-top: 85px;
        width: 260px;
      }
      @media screen and (min-width: 768px) {
        padding-top: 90px;
        width: 275px;
      }
      @media screen and (min-width: 900px) {
        width: 350px;
      }
      @media screen and (min-width: 1024px) {
        width: 440px;
      }
      @media screen and (min-width: 1150px) {
        width: 520px;
      }
      @media screen and (min-width: 1366px) {
        padding-top: 150px;
        width: 610px;
      }
      @media screen and (min-width: 1600px) {
        padding-top: 190px;
        padding-right: 130px;
        width: 830px;
      }
      img {
        width: 100%;
      }
    }
  }
}