form, input, textarea, select, button, p, div, header, footer, main, section, nav, audio, ul, ol, li, h1, h2, h3, h4, a, span {
  box-sizing: border-box; /* Ensures padding is not included for width calculations */
  margin: 0; /* Kill default values defined by the browser */
  padding: 0; /* Kill default values defined by the browser */
  font-family: inherit;
}

/* Kill default padding for body */
body {
  padding: 0;
  margin: 0;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, main, section {
  display: block;
}

/* No default list style for lists */
ol, ul {
  list-style: none;
}

/* No default spacing for table cells */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Kill default values for a tags */
a { 
  font-weight: normal;
  text-decoration: none;
  color: inherit;
}

/* Kill outlines in Chrome and Firefox for a tags, buttons, and input boxes */
/* WARNING: This potentially is an accessibility issue */
/* https://a11yproject.com/posts/never-remove-css-outlines/ */
button, a, input, select, option, textarea {
  outline: none;
}
