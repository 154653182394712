#services {
  position: relative; // For absolutely positioned background
  .background {
    position: absolute;
    width: 100%;
    @media screen and (min-width: 768px) {
      display: none;
    }
    .top {
      width: 100%;
      height: 50px;
      background-color: white;
      background-image: radial-gradient(circle at 50% calc(50px + 855px), $brownPale, $brownPale calc(900px), white calc(900px));
      @media screen and (min-width: 568px) {
        background-image: radial-gradient(circle at 50% calc(50px + 1755px), $brownPale, $brownPale calc(1800px), white calc(1800px));
      }
    }
    .bottom {
      width: 100%;
      height: 470px;
      background-color: $brownPale;
      background-image: radial-gradient(circle at 50% calc(490px + 830px), white, white calc(900px), $brownPale calc(900px));
      @media screen and (min-width: 568px) {
        background-image: radial-gradient(circle at 50% calc(490px + 1730px), white, white calc(1800px), $brownPale calc(1800px));
      }
    }
  }
  .contentWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    padding-top: 45px;
    height: 490px; // Necessary due to static height background
    h2 {
      width: 250px;
    }
    .items {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        .icon {
          width: 100px;
          color: black;
          font-size: 3.5rem;
          text-align: center;
        }
        .text {
          width: 150px;
          color: black;
          font-size: 1rem;
        }
        @media screen and (min-width: 900px) {
          .icon {
            font-size: 4.5rem;
          }
          .text {
            font-size: 1.1rem;
          }
        }
        @media screen and (min-width: 1024px) {
          .text {
            font-size: 1.15rem;
          }
        }
        @media screen and (min-width: 1150px) {
          .text {
            font-size: 1.2rem;
          }
        }
        @media screen and (min-width: 1366px) {
          .icon {
            font-size: 5rem;
          }
          .text {
            font-size: 1.25rem;
          }
        }
      }
    }
    .actionButtonWrapper {
      display: none;
      @media screen and (min-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        .actionButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 200px;
          height: 43px;
          background-color: $brownPrimary;
          border: solid 1px $brownPrimary;
          color: white;
          font-size: .95rem;
          border-radius: 5px;
          &:hover {
            background-color: $brownPaler;
            color: $brownPrimary;
          }
        }
        @media screen and (min-width: 900px) {
          margin-bottom: 75px;
          .actionButton {
            font-size: 1rem;
          }
        }
        @media screen and (min-width: 1024px) {
          .actionButton {
            font-size: 1.05rem;
          }
        }
        @media screen and (min-width: 1150px) {
          margin-bottom: 100px;
          .actionButton {
            font-size: 1.1rem;
          }
        }
        @media screen and (min-width: 1360px) {
          .actionButton {
            height: 48px;
            font-size: 1.2rem;
          }
        }
        @media screen and (min-width: 1600px) {
          margin-bottom: 130px;
        }
      }
    }
    @media screen and (min-width: 768px) {
      height: auto;
      background-image: radial-gradient(circle at center, $brownPaler, $brownPale);
      h2 {
        width: 100%;
      }
      .items {
        width: 650px;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        .item {
          flex-direction: column;
          .icon {
            margin-bottom: 30px;
          }
          .text {
            text-align: center;
          }
        }
      }
    }
    @media screen and (min-width: 900px) {
      padding-top: 60px;
    }
    @media screen and (min-width: 1150px) {
      padding-top: 90px;
    }
    @media screen and (min-width: 1366px) {
      .items {
        width: 700px;
        .item {
          margin-bottom: 90px;
        }
      }
    }
    @media screen and (min-width: 1600px) {
      padding-top: 120px;
    }
  }

}