#about {
  h2 {
    @media screen and (min-width: 568px) {
      text-align: right;
      padding-right: 30px;
    }
    @media screen and (min-width: 1366px) {
      padding-right: 60px;
    }
  }
  .contentWrapper {
    width: 100%;
    position: relative;  // For absolute positioning
    display: flex;
    flex-direction: column-reverse;
    @media screen and (min-width: 568px) {
      display: block;
      padding-bottom: 75px;
    }
    .imageContainer {
      width: 100%;
      margin-bottom: 15px;
      img {
        width: 100%;
      }
      @media screen and (min-width: 568px) {
        width: 400px;
      }
      @media screen and (min-width: 768px) {
        width: 500px;
      }
      @media screen and (min-width: 900px) {
        width: 600px;
      }
      @media screen and (min-width: 1024px) {
        width: 700px;
      }
      @media screen and (min-width: 1150px) {
        width: 800px;
      }
    }
    .textContainer {
      @media screen and (min-width: 568px) {
        width: 360px;
        position: absolute;
        z-index: 1;
        right: 0;
        margin-top: 135px;
        padding: 15px;
        background-color: $brownPale;
        box-shadow: 2px 2px 5px $grayLight;
        p {
          line-height: 1.6rem;
          font-size: .9rem;
          margin-bottom: 0;
        }
      }
      @media screen and (min-width: 768px) {
        width: 450px;
        margin-top: 190px;
        padding: 20px;
        background-image: radial-gradient(circle at center, $brownPaler, $brownPale);
        p {
          line-height: 1.75rem;
          font-size: .95rem;
        }
      }
      @media screen and (min-width: 900px) {
        width: 500px;
        margin-top: 230px;
        padding: 30px;
        p {
          line-height: 1.75rem;
          font-size: 1.05rem;
        }
      }
      @media screen and (min-width: 1024px) {
        width: 530px;
        margin-top: 260px;
        p {
          line-height: 2rem;
          font-size: 1.1rem;
        }
      }
      @media screen and (min-width: 1150px) {
        width: 550px;
        margin-top: 320px;
        p {
          line-height: 2rem;
          font-size: 1.15rem;
        }
      }
      @media screen and (min-width: 1366px) {
        width: 600px;
        margin-top: 300px;
        padding: 30px 45px;
        p {
          line-height: 2.25rem;
          font-size: 1.2rem;
        }
      }
    }
  }
}
