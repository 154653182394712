/* Standard Styles */

.bold {
  @include OpenSansBold;
  font-size: inherit;
}

.semibold {
  @include OpenSansSemiBold;
  font-size: inherit;
}

.italic {
  @include OpenSansItalic;
  font-size: inherit;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.hidden {
  display: none;
}

a, button {
  cursor: pointer;
}

/* Global Styles */

body {
  // Default font styles
  @include OpenSansRegular;
  color: black;
  font-size: 1rem;
  line-height: 1.5rem;
}

p {
  @include OpenSansRegular;
  font-size: .95rem;
  line-height: 1.75rem;
  text-align: left;
  margin-bottom: 15px;
  @media screen and (min-width: 768px) {
    margin-bottom: 20px;
  }
  @media screen and (min-width: 900px) {
    font-size: 1.05rem;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.1rem;
  }
  @media screen and (min-width: 1150px) {
    font-size: 1.15rem;
  }
  @media screen and (min-width: 1366px) {
    font-size: 1.2rem;
    line-height: 2rem;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.brown {
  color: $brownPrimary;
}

.purple {
  color: $purplePrimary;
}

.black {
  color: black;
}

/* Structural */

section {
  width: 100%;
  padding-top: 75px;
  @media screen and (min-width: 900px) {
    padding-top: 90px;
  }
  @media screen and (min-width: 1150px) {
    padding-top: 120px;
  }
  @media screen and (min-width: 1600px) {
    padding-top: 150px;
  }
}

.appContainer {
  background-color: white;
}

.viewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidePadding {
  padding-right: 15px;
  padding-left: 15px;
  @media screen and (min-width: 400px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media screen and (min-width: 768px) {
    padding-right: 45px;
    padding-left: 45px;
  }
  @media screen and (min-width: 900px) {
    padding-right: 60px;
    padding-left: 60px;
  }
  @media screen and (min-width: 1024px) {
    padding-right: calc(90px + ((100% - 1024px)/2));
    padding-left: calc(90px + ((100% - 1024px)/2));
  }
  @media screen and (min-width: 1150px) {
    padding-right: calc(90px + ((100% - 1150px)/2));
    padding-left: calc(90px + ((100% - 1150px)/2));
  }
  @media screen and (min-width: 1366px) {
    padding-right: calc(120px + ((100% - 1366px)/2));
    padding-left: calc(120px + ((100% - 1366px)/2));
  }
}

/* Headings */

h2 {
  @include OpenSansBold;
  font-size: 1.3rem;
  line-height: 1.5rem;
  margin-bottom: 15px;
  color: $purplePrimary;
  @media screen and (min-width: 768px) {
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 900px) {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1150px) {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
  @media screen and (min-width: 1366px) {
    font-size: 2.75rem;
    line-height: 3.5rem;
    margin-bottom: 45px;
  }
}

h3 {
  @include OpenSansBold;
  font-size: 1.1rem;
  line-height: 1.25rem;
  margin-bottom: 15px;
  color: $purplePrimary;
  @media screen and (min-width: 768px) {
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 900px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 1150px) {
    font-size: 1.7rem;
    line-height: 2rem;
  }
  @media screen and (min-width: 1366px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.headingDividerLeft, .headingDividerCenter {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  span {
    width: 30px;
    height: 3px;
    background-color: $brownPrimary;
    border-radius: 1.5px; // This actually seems to work, at least in Chrome.
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 45px;
    span {
      width: 60px;
    }
  }
  @media screen and (min-width: 1366px) {
    margin-bottom: 60px;
    span {
      width: 90px;
    }
  }
}

.headingDividerLeft {
  justify-content: flex-start;
}

.headingDividerCenter {
  justify-content: center;
}

/* Links */

a {
  @include OpenSansSemiBold;
  font-size: inherit;
  color: $brownPrimary;
}

/* Header Bar */

.headerBarDesktop {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
  }
  @media screen and (min-width: 900px) {
    height: 90px;
  }
  @media screen and (min-width: 1366px) {
    height: 120px;
  }
}

.headerBarMobile {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: $brownPale;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.headerBarContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 15px;
  @media screen and (min-width: 400px) {
    padding: 0 30px;
  }
  @media screen and (min-width: 768px) {
    padding: 0 45px;
  }
  @media screen and (min-width: 900px) {
    padding: 0 60px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: calc(60px + ((100% - 1024px)/2));
    padding-right: 60px;
  }
  @media screen and (min-width: 1150px) {
    padding-left: calc(60px + ((100% - 1150px)/2));
    padding-right: 60px;
  }
  @media screen and (min-width: 1366px) {
    padding-left: calc(90px + ((100% - 1366px)/2));
    padding-right: 90px;
  }
  @media screen and (min-width: 1600px) {
    padding-left: calc(90px + ((100% - 1620px)/2));
    padding-right: calc(90px + ((100% - 1620px)/2));
  }
  .headerBarText {
    padding-top: 5px;
    font-family: "Rabbid Highway Sign II";
    font-weight: initial; /* anchor tag override */
    line-height: 2rem;
    font-size: 1.75rem;
    letter-spacing: .05rem;
    .sloth {
      color: black;
    }
    .werks {
      color: $brownPrimary;
    }
    @media screen and (min-width: 1366px) {
      line-height: 2.5rem;
      font-size: 2rem;
      letter-spacing: .06rem;
    }
  }
  .headerBarLinks {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    a {
      color: black;
    }
    .phone {
      font-size: 1.6rem;
      margin-right: 15px;
    }
    .email {
      font-size: 2rem;
    }
    @media screen and (min-width: 768px) {
      .phone {
        font-size: 2rem;
        margin-right: 30px;
      }
      .email {
        font-size: 2.5rem;
      }
    }
    @media screen and (min-width: 1366px) {
      .phone {
        margin-right: 45px;
      }
    }
  }
}

/* Swipeable Views */

.swipeableViewsWrapper {
  width: 100%;
  margin-bottom: 15px;
  @media screen and (min-width: 768px) {
    display: none;
  }
  .item {
    padding: 3px 10px;
    @media screen and (min-width: 400px) {
      padding: 3px 20px;
    }
    @media screen and (min-width: 768px) {
      padding: 3px 30px;
    }
    .border {
      padding: 30px 15px;
      box-shadow: 0px 2px 5px $grayLight;
      h3 {
        text-align: center;
      }
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        .imageContainer {
          width: 120px;
          margin-bottom: 15px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.indicatorWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 768px) {
    display: none;
  }
  .indicator {
    height: 12px;
    width: 12px;
    margin: 0 2px;
    border: solid 1px $grayPrimary;
    border-radius: 50%;
    background-color: white;
    transition: background-color 0.3s;
  }
  .filled {
    background-color:$grayPrimary;
  }
}

/* Animations */

@keyframes readMoreBounce {
  from {
    padding-top: 0px;
  }
  to {
    padding-top: 10px;
  }
}

@keyframes splashTextFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
